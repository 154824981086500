<template>
  <div class="register pb-5">
    <div class="container">
      <div class="row mb-5">
        <div class="col-sm-10 mx-auto text-center">
          <img
            src="@/assets/img/reg_banner.jpg"
            class="img-fluid"
            alt="banner"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-10 mx-auto">
          <h1 class="h4 mb-3 b-700 text-uppercase">Registration</h1>

          <div class="mb-4">
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input
                v-model="participant.name"
                type="text"
                class="form-control"
              />
              <span
                class="text-danger"
                v-for="message of validation_message.name"
                :key="`name-${message}`"
                >{{ message }}<br
              /></span>
            </div>

            <div class="mb-3">
              <label class="form-label">Email address</label>
              <input
                type="email"
                v-model="participant.email"
                class="form-control"
              />
              <span
                class="text-danger"
                v-for="message of validation_message.email"
                :key="`email-${message}`"
                >{{ message }}<br
              /></span>
            </div>
          </div>

          <div class="row">
            <div class="col-6 col-sm-2 mx-auto">
              <div class="d-grid">
                <button
                  @click="store()"
                  v-if="!loading"
                  class="btn btn-primary text-uppercase"
                  type="button"
                >
                  Submit
                </button>
                <button
                  class="btn btn-primary text-uppercase"
                  disabled
                  v-if="loading"
                >
                  Please wait...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);
export default {
  name: "register",
  data() {
    return {
      loading: false,

      participant: {
        email: "",
        name: "",
      },
      validation_message: {
        email: [],
        name: [],
      },
    };
  },

  methods: {
    store() {
      this.loading = true;
      console.log("participant", this.participant);

      Csrf.getCookie().then((res) => {
        Api.post("participant", this.participant).then((res) => {
          if (res.data.validation_error) {
            this.validation_message = res.data.validation_message;
            this.loading = false;
            return;
          }
          if (!res.data.error) {
            this.loading = false;
            this.resetValidation();
            this.sucess();
          }
        });
      });
    },
    resetValidation() {
      this.validation_message = {
        email: "",
        name: "",
      };
    },
    sucess() {
      this.reg_key = "";

      this.participant.name = "";
      this.participant.email = "";

      this.$swal({
        icon: "success",
        text: "Thank you for your response!",
        type: "success",
        confirmButtonColor: "#c21f19",
      }).then(function () {});
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  .form-label {
    font-weight: 600;
  }
}
</style>
